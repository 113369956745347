/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useRef, useState} from "react";
import "../notification.css";
import convertCreatedAtToTimeAgo from "../../../utils/common-functions/ConvertCreatedAtToTimeAgo";
import { Link } from "react-router-dom";
import defaultImage from "../../../assets/paxton-patterson-logo.png";
import notFound from "../../../assets/not-found/not_found.png";
import NotificationLoader from "../../../components/common/NotificationLoader";
import {Notification} from "../../../components/Notification/Notification";
import CommentLoader from "../../../components/common/CommentLoader";
import {notificationService} from "../../../services/notificationService/NotificationServices";

const NotificationsDropdown = () => {

    const [notifications, setNotifications] = useState([]);
    const notificationsRef = useRef(null);
    const [nextCursor, setNextCursor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const allNotificationsData = async () => {
        setIsLoading(true);
        try {
            const res = await notificationService.getUserNotifications(nextCursor);

            if (nextCursor) {
                setNotifications((prevNotifications) => [...prevNotifications, ...res.data.data]);
            }
            if (!nextCursor) {
                setNotifications(res.data.data);
            }

            setNextCursor(res.data.meta.next_cursor);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        allNotificationsData();
    }, []);

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = notificationsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 20) {
            if (nextCursor) {
                allNotificationsData();
            }
        }
    };

    useEffect(() => {
        const notificationsElement = notificationsRef.current;
        if (notificationsElement) {
            notificationsElement.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (notificationsElement) {
                notificationsElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [nextCursor]);

    return (
        <>
            <div className={`dashboard-dropdown dropdown-menu dropdown-mobile`} aria-labelledby="notificatioDropdown">
                <div
                    className=" menu-item fs-6 p-3 text-white d-flex justify-content-between rounded-top"
                    style={{
                        backgroundColor: "rgba(28, 34, 55, 1)",
                    }}
                >
                    <span>Notifications</span>
                </div>
                <div className="separator border-gray-400 my-0"></div>
                <div className="menu-item" style={{ maxHeight: "300px", overflowY: "auto" }} ref={notificationsRef}>
                    {isLoading && !nextCursor ? (
                        <NotificationLoader />
                    ) : (
                        <div className="menu-content">
                            {notifications?.length ? (
                                <div>
                                    {notifications?.map((notification) => (
                                        <Notification
                                            notification={notification}
                                        />
                                    ))}
                                    {isLoading && nextCursor &&
                                        <CommentLoader/>
                                    }
                                </div>
                            ) : (
                                <div className="w-100 shadow p-3 mt-3 rounded-4 text-center">
                                    <img crossOrigin="anonymous" src={notFound} alt="data not found" width={200} />
                                    <h5>No new notifications added</h5>
                                    <span className="fs-6 fw-lighter">No new notifications at the moment.</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationsDropdown;
