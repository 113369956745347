import apiClient from "../../utils/apiClient";
import apiService from "../apiService/apiService";

const confirmWatching = () => {
    return apiClient.post("/watch-welcome-video");
};

const getWelcomeVideoUrl = (type = null) => {
    const params = {
        'video_type': type,
    };

    const queryString = apiService.getRequestParams(params);

    return apiClient.get(`/welcome-video-url${queryString ? '?' + queryString : ''}`);
};

export const welcomeVideoService = {
    confirmWatching,
    getWelcomeVideoUrl
};