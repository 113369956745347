import React, { useEffect, useState } from "react";
import "./profileSettings.css";
import ProfileSettingsForm from "./ProfileSettingsForm";
import ProfileImageUpdated from "./ProfileImageUpdated";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/authSlice";
import Loader from "../../components/common/Loader";
import AchievementsListCard from "../../components/Achievements/AchievementsListCard";
import useRenderTextEditorContent from "../../hooks/useTextEditorContent";
import {useNavigate} from "react-router-dom";

const ProfileSettings = () => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.auth);
    const [getUserData, setGetUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const renderAnswer = useRenderTextEditorContent();
    const navigate = useNavigate();
    const userData = useSelector((state) => state?.auth?.userData);

    const dispatch = useDispatch();
    // states end

    // useEffect for getting all user data
    useEffect(() => {
        getUserAllData();
    }, []);

    const getUserAllData = async () => {
        try {
            const response = await profileSettingService.getUserProfileData(loginUserData?.id);
            const fetchedUserData = response?.data?.data;
            dispatch(setUserData(fetchedUserData));
            setGetUserData(fetchedUserData);
            setIsLoading(false);
        } catch (error) {
            console.error("API error:", error);
            setIsLoading(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <h3 className="d-lg-block d-none ">Profile Settings</h3>
            <div className="card-layout mt-4 profile-settings-section">
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="d-flex justify-content-between flex-wrap">
                            <ProfileImageUpdated getUserAllData={getUserAllData} getUserData={getUserData}
                                                 setIsLoading={setIsLoading}/>
                            <button
                                className="btn btn-primary-info py-2 px-3 height-max-content"
                                onClick={() => navigate(`/community/users/${userData?.id}`)}
                            >
                                View Public Profile
                            </button>
                        </div>
                        <div className="mt-4">
                            <ProfileSettingsForm getUserAllData={getUserAllData} getUserData={getUserData}
                                                 setIsLoading={setIsLoading}/>
                        </div>
                    </>
                )}
            </div>
            <div className="mt-4">
                <AchievementsListCard
                    achievements={userData?.badges}
                    unopenedBadges={userData?.unopened}
                />
            </div>
        </>
    );
};

export default ProfileSettings;
