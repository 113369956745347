import React from "react";
import "../programs.css";
import ProgramMasterclassCard from "./ProgramMasterclassCard";

const ProgramMasterclassList = ({ masterClasses }) => {

    return (
        <>
            <h2>Master Courses</h2>
            <div className="row">
                {masterClasses?.map((masterClass) => {
                    return (
                        <React.Fragment key={masterClass.id}>
                            <ProgramMasterclassCard
                                masterClass={masterClass}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default ProgramMasterclassList;