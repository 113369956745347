import {LiveEventScheduledNotification} from "./LiveEventScheduledNotification";
import {DefaultNotification} from "./DefaultNotification";

export const NotificationContent = ({ notification, style }) => {

    if (notification.entities.length === 0) {
        return <DefaultNotification notification={notification}/>
    }

    return (
        <div className="notification__content" style={style}>
            <div className="notification__content__heading">
                {notification.sender &&
                    <div className="notification__content__heading__name">
                        {notification.sender?.name}
                    </div>
                }
                <div className="notification__content__heading__time">
                    {notification.created_at}
                </div>
            </div>
            <div className="notification__content__data">
                {(() => {

                    switch (notification.type) {
                        case 'live-event':
                            return <LiveEventScheduledNotification
                                notification={notification}
                            />;
                        default:
                            return <></>;
                    }

                })()}
            </div>
        </div>
    );
};