/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment-timezone";
import "../Style/Schedular.css";
import { CustomToolbar } from "../CustomToolbar";
import Swal from "sweetalert2";
import { schedularService } from "../../../services/schedularCalendarService/SchedularCalendarService";
import ShowAllEvents from "../Modal/ShowAllEvents";
import { useNavigate } from "react-router-dom";
import { ZOOM_SESSION } from "../../../lib/sessionTypes";
import "../Style/TimeZone.css";
import convertUtcToSelectedTimeZone from "../../../utils/common-functions/ConvertUtcToTimezone";
import TimezoneSelect from "./TimeZoneSelector";
import { MEETING_TYPES, ROUTES } from "../../../constants";
import { RiCheckboxBlankFill } from "react-icons/ri";

const defaultTZ = "PST8PDT";

const PocScheduleCalendar = ({ formats, handleViewChange, currentDate, currentView, dayPropGetter, setIsLoading }) => {
    // states start
    const [schedularData, setSchedularData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [showCustomTooltip, setShowCustomTooltip] = useState(false);
    const [allMeetings, setAllMeetings] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [userTimeZone, setUserTimeZone] = useState(null);
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();
    const [timezone, setTimezone] = useState(defaultTZ);
    // states end

    // useEffect of pass system timeZone in api
    useEffect(() => {
        const getUserTimeZone = () => {
            if ("Intl" in window && "DateTimeFormat" in Intl) {
                setUserTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC");
            } else {
                setUserTimeZone("UTC");
            }
        };

        getUserTimeZone();
    }, []);

    // call api for get all availability of success coach
    const allCalendarData = () => {
        schedularService.getCalendarData().then((response) => {
            setSchedularData(response?.data?.calendar);
            setFlag(response?.data?.isMeetingScheduled);
            setIsLoading(false);
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        allCalendarData();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleSession = (item) => {
        if (item.type === "previous_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item.keyNote?.id));
        } else if (item.type === "meeting") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.successCoachMeeting).replace(":id", item?.id));
        } else if (item.type === "speaker_availability_meetings") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.speackerMeeting).replace(":id", item?.id));
        } else if (item.type === "completed_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item.keyNote?.id));
        } else if (item.type === "special_event") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.specialEvent).replace(":id", item?.id));
        }
        //@todo: other trigger need to be implement
        navigate("/dashboard", {
            state: {
                src: item?.meetingLink,
                sessionType: ZOOM_SESSION,
                sessionStartTime: item?.startTime,
            },
        });
        scrollToTop();
    };

    // function for available schedules devided to 1 hour schedule
    function generateHourlySlots(start, end) {
        const slots = [];
        let current = moment(start);

        while (current.isBefore(end)) {
            const nextHour = moment(current).add(1, "hour");
            if (nextHour.isAfter(end)) {
                break;
            }
            slots.push({
                start: convertUtcToSelectedTimeZone(current.toDate()),
                end: convertUtcToSelectedTimeZone(nextHour.toDate()),
            });
            current = nextHour;
        }
        return slots;
    }

    // function for available schedules devided to 30 minutes schedule
    function generateMinutesSlots(start, end) {
        const slots = [];
        let current = moment(start);

        while (current.isBefore(end)) {
            const next30Minutes = moment(current).add(30, "minutes");
            if (next30Minutes.isAfter(end)) {
                break;
            }
            slots.push({
                start: convertUtcToSelectedTimeZone(current.toDate()),
                end: convertUtcToSelectedTimeZone(next30Minutes.toDate()),
            });
            current = next30Minutes;
        }
        return slots;
    }

    // === Event 1 === show events for starting availability of success coach
    const availabilityEvents =
        (Array.isArray(schedularData?.POC?.poc_success_coach_availability?.start_slot) &&
            schedularData?.POC?.poc_success_coach_availability?.start_slot
                .flatMap((item) => {
                    const eventSlots = generateHourlySlots(convertUtcToSelectedTimeZone(item.start_date), convertUtcToSelectedTimeZone(item.end_date));

                    const bookedSlots = item.success_coach_booked_slots?.map((slot) => ({
                        start: convertUtcToSelectedTimeZone(`${slot.start_date}`),
                        end: convertUtcToSelectedTimeZone(`${slot.end_date}`),
                    }));

                    const currentDate = new Date();

                    const availableSlots = eventSlots.filter((slot) => {
                        return (
                            slot.start >= currentDate &&
                            !bookedSlots.some((bookedSlot) => {
                                return slot.start >= bookedSlot.start && slot.start < bookedSlot.end;
                            })
                        );
                    });

                    return availableSlots?.map((slot, index) => ({
                        id: `${item.id}`,
                        title: `${item.success_coach?.first_name} ${item.success_coach?.last_name}`,
                        start: slot.start,
                        end: slot.end,
                        coachName: `${item.success_coach?.first_name} ${item.success_coach?.last_name}`,
                        date: `${item.date}`,
                        type: "availability",
                        masterClass: `${item?.master_class?.name}`,
                    }));
                })
                .flat()) ||
        [];

    // === Event 2 === show events for ending availability of success coach
    const lastArray = schedularData?.POC?.poc_success_coach_availability?.end_slot;

    const lastArrayEvents = [];

    if (Array.isArray(lastArray)) {
        // Iterate through the inner array
        lastArray.forEach((item) => {
            const eventLastSlots = generateHourlySlots(convertUtcToSelectedTimeZone(`${item.start_date}`), convertUtcToSelectedTimeZone(`${item.end_date}`));

            const bookedLastSlots = item?.success_coach_booked_slots?.map((slot) => ({
                start: convertUtcToSelectedTimeZone(`${slot.start_date}`),
                end: convertUtcToSelectedTimeZone(`${slot.end_date}`),
            }));

            const availableLastSlots = eventLastSlots.filter((slot) => {
                return !bookedLastSlots.some((bookedSlot) => {
                    return slot.start >= bookedSlot.start && slot.start < bookedSlot.end;
                });
            });

            availableLastSlots.forEach((slot) => {
                lastArrayEvents.push({
                    id: `${item.id}`,
                    title: `${item.success_coach?.first_name} ${item.success_coach?.last_name}`,
                    start: slot.start,
                    end: slot.end,
                    coachName: `${item.success_coach?.first_name} ${item.success_coach?.last_name}`,
                    date: `${item.date}`,
                    type: "availability",
                    masterClass: `${item?.master_class?.name}`,
                });
            });
        });
    }

    // === Event 3 === show events for success coach meeting schedule of login user
    const scheduleMeetingEvents =
        (Array.isArray(schedularData?.POC?.poc_success_coach_meting_details) &&
            schedularData?.POC?.poc_success_coach_meting_details
                ?.map((item) => {
                    const eventSlots = generateHourlySlots(convertUtcToSelectedTimeZone(item.start_date), convertUtcToSelectedTimeZone(item.end_date));

                    return eventSlots.map((slot) => ({
                        id: `${item.id}`,
                        title: `${item?.success_coach_availability?.success_coach?.first_name} ${item?.success_coach_availability?.success_coach?.last_name}`,
                        start: slot.start,
                        end: slot.end,
                        coachName: `${item?.success_coach_availability?.success_coach?.first_name} ${item?.success_coach_availability?.success_coach?.last_name}`,
                        date: `${item.date}`,
                        meetingLink: item?.meeting_details?.link,
                        type: "meeting",
                        masterClass: `${item?.success_coach_availability?.master_class?.name}`,
                        handleSession: handleSession,
                        startTime: item?.start_time,
                        endTime: item?.end_time,
                    }));
                })
                .flat()) ||
        [];

    // === Event 4 === Function to generate events for speaker availability
    const generateSpeakerAvailabilityEvents = () => {
        if (!Array.isArray(schedularData?.POC?.poc_speaker_availability)) {
            return [];
        }

        const events = [];

        if (flag) {
            Array.isArray(schedularData?.POC?.poc_speaker_availability) &&
                schedularData?.POC?.poc_speaker_availability.forEach((item) => {
                    // Create events from speaker_availability data
                    item.speaker_availability.forEach((availability) => {
                        let startDateTime;

                        if (availability?.consider_start_time === null) {
                            startDateTime = convertUtcToSelectedTimeZone(`${availability.start_date}`);
                        } else {
                            startDateTime = convertUtcToSelectedTimeZone(`${availability?.consider_start_time}`);
                        }

                        const endDateTime = convertUtcToSelectedTimeZone(`${availability.end_date}`);

                        // Generate 30-minute slots using generateHourlySlots function
                        const slots = generateMinutesSlots(startDateTime, endDateTime);

                        slots.forEach((slot) => {
                            if (new Date(slot.start) < new Date()) {
                                return;
                            }
                            events.push({
                                id: availability.id,
                                title: item?.key_notes_details?.speaker?.first_name + " " + item?.key_notes_details?.speaker?.last_name,
                                start: slot.start,
                                end: slot.end,
                                // keyNote: item?.key_notes_details?.key_note?.name,
                                speakerName: item?.key_notes_details?.speaker?.first_name + " " + item?.key_notes_details?.speaker?.last_name,
                                masterClassName: item?.masterClass_name,
                                type: "speaker_availability",
                            });
                        });
                    });
                });

            return events;
        } else {
            return [];
        }
    };

    const speakerAvailabilityEvents = generateSpeakerAvailabilityEvents();

    // === Event 5 === show events for meeting schedule of login user for speaker
    const showSpeakerScheduleMeeting = () => {
        const events = [];

        schedularData?.POC?.poc_speaker_meeting_details?.map((item) => {
            const startDateTime = convertUtcToSelectedTimeZone(`${item.start_date}`);
            const endDateTime = convertUtcToSelectedTimeZone(`${item.end_date}`);

            events.push({
                id: item.id,
                title: item?.institute_key_notes?.speaker?.first_name + " " + item?.institute_key_notes?.speaker?.last_name,
                start: new Date(startDateTime),
                end: new Date(endDateTime),
                speakerName: item?.institute_key_notes?.speaker?.first_name + " " + item?.institute_key_notes?.speaker?.last_name,
                keyNote: item?.institute_key_notes?.key_note?.name,
                meetingLink: item?.meeting_details?.link,
                type: "speaker_availability_meetings",
                handleSession: handleSession,
                meetingDetails: item?.meeting_details,
                meetingDetailsId: item?.meeting_details_id,
                startTime: moment(item?.start_date).format("HH:mm:ss"),
                endTime: moment(item?.end_date).format("HH:mm:ss"),
            });
        });

        return events;
    };
    // === Event 6 === Show session of poc
    const showPreviousSession = () => {
        const session = schedularData?.POC?.sessions_details;
        const previousKeyNotes = session?.institution_key_notes;

        if (flag) {
            const previousSessionEvents = Array.isArray(previousKeyNotes)
                ? previousKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          keyNote: item?.key_note,
                          type: "previous_keynotes",
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];
            return previousSessionEvents;
        } else {
            return [];
        }
    };

    const previousSessionEvents = showPreviousSession();

    // === Event 7 === Show Completed session of poc
    const showCompletedSession = () => {
        const session = schedularData?.POC?.sessions_details;
        const completedKeyNotes = session?.completed_key_notes;
        if (flag) {
            const completedSessionEvents = Array.isArray(completedKeyNotes)
                ? completedKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item?.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item?.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          keyNote: item?.key_note,
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                          type: "completed_keynotes",
                      };
                  })
                : [];

            return completedSessionEvents;
        } else {
            return [];
        }
    };

    const completedSessionEvents = showCompletedSession();

    // === Event 8 === Show Live Events
    const showSpecialEvents = () => {
        const event = schedularData?.POC?.special_events;

        if (flag) {
            const specialEvents = Array.isArray(event)
                ? event.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.title,
                          type: "special_event",
                          handleSession: handleSession,
                          meetingDetails: item?.meeting_details?.details,
                          meetingDetailsId: item?.meeting_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];
            return specialEvents;
        } else {
            return [];
        }
    };

    // function for open sweet alert popup
    const handleEventClick = (event) => {
        setShowAll(false);
        const { title, start, end, type, id, masterClass, keyNote, speakerName, masterClassName } = event;

        const startTime = moment(start).format("hh:mm A");
        const endTime = moment(end).format("hh:mm A");
        const isMeetingEnd = moment().tz(timezone).isAfter(moment(end));
        if (type === "availability") {
            Swal.fire({
                title: "Success Coach Meeting Confirmation",
                html: `
          <p><strong>Coach Name:</strong> ${title}</p>
          <p><strong>Master Course:</strong> ${masterClass}</p>
          <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
          <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
        `,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    const data = {
                        meeting_title: "",
                        success_coach_availability_id: id,
                        date: moment(start).format("YYYY-MM-DD"),
                        start_time: startTime,
                        end_time: endTime,
                        timezone: timezone,
                    };

                    schedularService
                        .scheduleMeeting(data)
                        .then((response) => {
                            Swal.fire("Confirmed!", "Your meeting has been confirmed.", "success");
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            Swal.fire("Error", `${error?.response?.data?.message}`, "error");
                            setIsLoading(false);
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
            });
        } else if (type === "meeting") {
            Swal.fire({
                title: "Success Coach Meeting Details",
                html: `
          <p><strong>Coach Name:</strong> ${title}</p>
          <p><strong>Master Course:</strong> ${masterClass}</p>
          <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
          <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
        `,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "speaker_availability") {
            Swal.fire({
                title: "Speaker Meeting Confirmation",
                html: `
          <p><strong>Speaker Name:</strong> ${speakerName}</p>
          <p><strong>Master Course:</strong> ${masterClassName}</p>
          <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
          <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
        `,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    const data = {
                        meeting_title: "",
                        speaker_availability_id: event?.id?.toString(),
                        date: moment(start).format("YYYY-MM-DD"),
                        start_time: startTime,
                        end_time: endTime,
                        timezone: timezone,
                    };
                    schedularService
                        .bookedSpeakerMeetings(data)
                        .then((response) => {
                            Swal.fire("Confirmed!", "Your meeting has been confirmed.", "success");
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            Swal.fire("Error", `${error?.response?.data?.message}`, "error");
                            setIsLoading(false);
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
            });
        } else if (type === "speaker_availability_meetings") {
            Swal.fire({
                title: "Speaker Meeting Details",
                html: `
        <p><strong>Speaker Name:</strong> ${speakerName}</p>
        <p><strong>Keynote:</strong> ${keyNote}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Join Now",
                showConfirmButton: !isMeetingEnd,
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "previous_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Master Course Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "completed_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "previous_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "completed_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "special_event") {
            Swal.fire({
                title: "Live Event Details",
                html: `
        <p><strong>Live Event Name:</strong> ${title}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        }
    };

    // Function to determine the background color of events
    const eventStyleGetter = (event) => {
        if (event.type === "meeting") {
            return {
                style: {
                    backgroundColor: "#e46a76",
                    color: "black",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "availability") {
            return {
                style: {
                    backgroundColor: "#c369ec",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "speaker_availability") {
            return {
                style: {
                    backgroundColor: "#8794ef",
                },
            };
        } else if (event.type === "speaker_availability_meetings") {
            return {
                style: {
                    backgroundColor: "#fec107",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "previous_keynotes") {
            return {
                style: {
                    backgroundColor: "#03a9f3",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "completed_keynotes") {
            return {
                style: {
                    backgroundColor: "#4CCABA",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "special_event") {
            return {
                style: {
                    backgroundColor: "#8bc34a",
                    marginTop: "25px",
                },
            };
        }

        return {};
    };

    // function for show all events
    const handleShowMoreClick = (events, date, view) => {
        setShowAll(true);
        setAllMeetings(events);
        setShowCustomTooltip(true);
    };

    // function for close show all events popup
    const closeCustomTooltip = () => {
        setShowCustomTooltip(false);
        setShowAll(false);
    };

    // combine events
    const combinedEvents = [
        ...availabilityEvents,
        ...scheduleMeetingEvents,
        ...lastArrayEvents,
        ...speakerAvailabilityEvents,
        ...previousSessionEvents,
        ...completedSessionEvents,
        ...showSpeakerScheduleMeeting(),
        ...showSpecialEvents(),
    ];

    const { scrollToTime } = useMemo(() => {
        moment.tz.setDefault(timezone);
        return {};
    }, [timezone]);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="d-lg-block d-none ">Calendar</h3>
            </div>
            <div className="card-layout schedular-section">
                <div className="calendar-container">
                    <div className="row m-0 py-3 px-4">
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#4CCABA" fontSize={12} />
                                <span style={{ fontSize: "12px" }}>Completed Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#03a9f3" fontSize={12} />

                                <span style={{ fontSize: "12px" }}> Upcoming Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#8794ef" fontSize={12} />

                                <span style={{ fontSize: "12px" }}>Speaker Availability</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#c369ec" fontSize={12} />
                                <span style={{ fontSize: "12px" }}> Success Availability</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#e46a76" fontSize={12} />
                                <span style={{ fontSize: "12px" }}> Success Coach & POC Meeting</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#fec107" fontSize={12} />
                                <span style={{ fontSize: "12px" }}> Speaker & POC Meeting</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#8bc34a" fontSize={12} />
                                <span style={{ fontSize: "12px" }}>Live Event</span>
                            </div>
                        </div>
                    </div>
                    <div className="rbc-toolbar px-4 py-2 justify-content-start gap-3">
                        <span className="fs-6 fw-bold">Select Time Zone :</span>
                        <TimezoneSelect defaultTZ={defaultTZ} setTimezone={setTimezone} timezone={timezone} />
                    </div>

                    <Calendar
                        components={{
                            toolbar: (props) => (
                                <>
                                    <CustomToolbar {...props} onViewChange={handleViewChange} currentView={currentView} />
                                </>
                            ),
                        }}
                        localizer={localizer}
                        formats={formats}
                        view={currentView}
                        events={combinedEvents}
                        date={currentDate.toDate()}
                        style={{ height: 1260, color: "#fff", backgroundColor: "#374368" }}
                        startAccessor="start"
                        endAccessor="end"
                        dayPropGetter={dayPropGetter}
                        onSelectEvent={handleEventClick}
                        eventPropGetter={eventStyleGetter}
                        onShowMore={handleShowMoreClick}
                        scrollToTime={scrollToTime}
                    />
                </div>
            </div>

            {showCustomTooltip && <ShowAllEvents showAll={showAll} events={allMeetings} onClose={closeCustomTooltip} handleEventClick={handleEventClick} />}
        </>
    );
};

export default PocScheduleCalendar;
