import React, {useEffect, useState} from "react";
import useDebounce from "../../../hooks/useDebounce";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import DiscussionsList from "./DiscussionsList";
import {FiSearch} from "react-icons/fi";
import {Pagination} from "../../../components/Pagination/Pagination";
import {useParams} from "react-router-dom";
import CreateCommunityDiscussionModal from "../modals/CreateCommunityDiscussionModal";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";

const GeneralDiscussions = () => {
    const [generalDiscussions, setGeneralDiscussions] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [requestPage, setRequestPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const { id } = useParams();
    const [showCreateDiscussionModal, setShowCreateDiscussionModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getGeneralDiscussions();
    }, [requestPage, debouncedSearchTerm]);

    useEffect(() => {
        setRequestPage(1);
    }, [debouncedSearchTerm]);

    const getGeneralDiscussions = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getGeneralDiscussions(id,12, requestPage, searchTerm);
            setGeneralDiscussions(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.last_page);
            setCategoryData(response.data.category);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setRequestPage(page);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row">
                            <CustomBreadcrumbs
                                currentPageTitle={categoryData?.title}
                                pages={[
                                    {
                                        title: 'Community',
                                        to: `/community`
                                    },
                                ]}
                            />
                        </div>
                        <DiscussionsList
                            title={categoryData?.title}
                            discussions={generalDiscussions}
                            navigateTo={`/community/${id}/discussions/`}
                            sideComponent={
                                <div className="d-flex flex-column align-items-end">
                                    <button className="btn btn-primary mb-2" style={{width: "max-content"}}
                                            onClick={() => setShowCreateDiscussionModal(true)}>
                                        Start a new topic
                                    </button>
                                    <div className="search-container">
                                        <input onChange={handleSearch} value={searchTerm} placeholder="Search"
                                               className="search-input" autoFocus={true}/>
                                        <FiSearch className="search-icon"/>
                                    </div>
                                </div>
                            }
                        />
                        <div className="mt-2">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        {showCreateDiscussionModal &&
                            <CreateCommunityDiscussionModal
                                onClose={() => setShowCreateDiscussionModal(false)}
                                categoryId={id}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default GeneralDiscussions;