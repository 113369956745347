import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DiscussionsList from "./DiscussionsList";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";

const DiscussionByCategory = ({ discussionCategory }) => {
    const navigate = useNavigate();
    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getDiscussions();
    }, []);

    const getDiscussions = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getGeneralDiscussions(discussionCategory.id,3);
            setDiscussions(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <DiscussionsList
            title={discussionCategory.title}
            discussions={discussions}
            navigateTo={`/community/${discussionCategory.id}/discussions/`}
            viewAllPath={`/community/${discussionCategory.id}/discussions/`}
            sideComponent={
                <button className="btn btn-primary" onClick={() => navigate(`/community/${discussionCategory.id}/discussions/`)}>View All</button>
            }
        />
    );
};

export default DiscussionByCategory;