import React from 'react';
import {useNavigate} from "react-router-dom";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import ProgressBar from "@ramonak/react-progress-bar";
import {useSelector} from "react-redux";
import helpers from "../../../utils/common-functions/Helper";

const MasterClassCard = ({ masterClass }) => {
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();
    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleNavigate = (event) => {
        event.stopPropagation();
        navigate("/master-classes/" + masterClass?.id);
        scrollToTop();
    };

    return (
        <>
            <div className={`text-white card px-5 py-4 cursor-pointer program-masterclass-card rounded-3 ${
                masterClass?.is_completed ? "overlay-completed program-completed-opacity" : ""
            }`}
                 onClick={handleNavigate}>
                <div className="row no-gutters">
                    <div className="col-md-4 d-flex align-items-center">
                        <img src={masterClass?.cover_image} className="card-img master-class-lesson__card-thumbnail img-fluid rounded-3" alt={masterClass?.name}/>
                    </div>
                    <div className="col-md-8">
                        <div className="master-class-lesson__card__content">
                            <div className="mt-3">
                                <span className="fs-4 lh-base fw-bolder">
                                    {masterClass?.name}
                                </span>
                            </div>
                            {masterClass?.intersected_programs &&
                                <div className="mt-1">
                                    <span className="fs-6 lh-base fw-bolder text-secondary">
                                        {masterClass?.intersected_programs}
                                    </span>
                                </div>
                            }
                            <div className="program-card-description mt-3">
                                {
                                    masterClass?.description && renderAnswer(masterClass?.description)?.map((element, index) =>
                                        <React.Fragment key={index}>{element}</React.Fragment>)
                                }
                            </div>
                            {userType !== 4 &&
                                <div className="mt-3">
                                    <ProgressBar
                                        completed={masterClass?.progress}
                                        bgColor="#1c64f2"
                                        baseBgColor="#374151"
                                        className="w-75 progressbar-count"
                                    />
                                    {masterClass?.completed_lessons > 0 &&
                                        <div className="mt-1">
                                            <span className="fs-6 lh-base fw-bolder text-secondary">
                                                {helpers.pluralize(masterClass?.completed_lessons, "watched lesson", "watched lessons")}
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MasterClassCard;