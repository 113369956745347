import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {programsService} from "../../../services/programsService/programsService";
import Loader from "../../../components/common/Loader";
import DashboardProgramsList from "./DashboardProgramsList";
import Calendar from "../../../components/Calendar/Calendar";
import DashboardAnnouncements from "./DashboardAnnouncements";
import {welcomeVideoService} from "../../../services/authService/welcomeVideoService";
import RecordedVideoSession from "./RecordedVideoSession";

const InstitutionUserDashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [videoUrl, setVideoUrl] = useState(null);

    const [state, setState] = useState({
        loader: false,
    });

    const [programs, setPrograms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getAllPrograms();
        getVideoLink();
    }, []);

    const getAllPrograms = async () => {
        try {
            const response = await programsService.getPrograms();

            setPrograms(response.data['programs']);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getVideoLink = async () => {
        try {
            const response = await welcomeVideoService.getWelcomeVideoUrl('dashboard');
            setVideoUrl(response.data);
        } catch (error) {
            console.log('error during getting welcome video');
            setVideoUrl(null);
        }
    };

    return (
        <>
            {state?.loader || isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="container-fluid dashboard-content">
                        <div className="row mb-5">
                            <DashboardAnnouncements />
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h1>Driving Educational Growth with Expert Training</h1>
                                <span className="fs-5 text-second fs-lg-4">
                                   Paxton/Patterson provides educational institutions with highly impactful professional development master courses featuring influential thought leaders, subject matter experts, and insightful coaching. We ignite a tipping point that advances institutional culture forward faster.
                                </span>
                                <div className="d-flex mt-3">
                                    <button className="btn btn-primary me-2 py-2 px-3" onClick={() => navigate('/programs')}>Choose Program</button>
                                    <button
                                        className="btn btn-primary-info py-2 px-3"
                                        onClick={() => navigate('/programs')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-6 text-center">
                                {videoUrl
                                    ? <div className="master-class__image">
                                        <RecordedVideoSession video={videoUrl}/>
                                      </div>
                                    : <Loader />
                                }
                            </div>
                        </div>
                        <div className="single-lesson-grid">
                            <div className="py-3">
                                <DashboardProgramsList
                                    programs={programs}
                                />
                            </div>
                            <div className="py-3">
                                <h3>Events</h3>
                                <div className="card-layout-calendar">
                                    <Calendar/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default InstitutionUserDashboard;