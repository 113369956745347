/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../projects.css";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import Loader from "../../../components/common/Loader";
import notFound from "../../../assets/not-found/not_found.png";
import defaultImage from "../../../assets/avatarUser.png";
import ShowProjectsDetails from "./ShowProjectsDetails";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import Pagination from "@mui/material/Pagination";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import { useNavigate } from "react-router-dom";
import imageNotFound from "../../../assets/paxton-patterson-logo.png";

const AllProjects = () => {
    const [key, setKey] = useState("allProjects");
    const [projectsData, setProjectsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [likeStatus, setLikeStatus] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const limit = 12;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    // call api for get all projects data
    const allProjectsData = () => {
        setIsLoading(true);
        ProjectsService.getAllProjectsData(limit, page).then((response) => {
            setProjectsData(response?.data?.data);
            setIsLoading(false);
            setTotal(Math.ceil(response?.data?.total_records / limit));
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Handle the click on a project card
    const handleProjectClick = (projectId) => {
        ProjectsService.addView({ project_id: projectId }).then((response) => {});
        navigate(`/impacts/all-impacts/${projectId}`, { state: "allProject" });
        scrollToTop();
    };

    // useEffect of call all api function
    useEffect(() => {
        allProjectsData();
    }, [page]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-column">
                <h3 className="d-lg-block m-0 d-none">Impacts</h3>
                <span className="mt-2">This is where you can see the impacts Paxton Patterson has had on Institutions that participated in the Master Courses.</span>
            </div>
            <div className="outer-card-layout mt-2 mt-lg-4 mt-md-4 all-projects-layout">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {key == "allProjects" && (
                            <>
                                {projectsData?.length > 0 ? (
                                    <div className="row">
                                        {projectsData?.map((data) => {
                                            const timeAgo = calculateTimeDifference(data?.created_at);

                                            return (
                                                <div
                                                    className="col-md-4 col-lg-6 col-xl-4 col-sm-6 mt-3 mt-lg-4 mt-md-4 cursor-pointer mobile-view"
                                                    onClick={() => handleProjectClick(data.id)}
                                                    key={data?.id}
                                                >
                                                    <div className="margin-lr">
                                                        <>
                                                            <div className="all-project-image">
                                                                {data?.full_cover_image === null ? (
                                                                    <img
                                                                        crossOrigin="anonymous"
                                                                        className="projects-cover-image"
                                                                        src={imageNotFound}
                                                                        alt="Cover Image"
                                                                        style={{ border: "1px solid black" }}
                                                                    />
                                                                ) : (
                                                                    <img crossOrigin="anonymous" className="projects-cover-image" src={data?.full_cover_image} alt="Cover Image" />
                                                                )}
                                                            </div>
                                                            <div className="d-flex gap-20 image-info">
                                                                <div>
                                                                    {likeStatus === true || data?.hasUserLiked === 1 ? <AiFillHeart fill="red" fontSize={20} /> : <AiOutlineHeart fontSize={20} />}

                                                                    <span className="image-text-span fs-6">{data?.project_likes}</span>
                                                                </div>
                                                                <div>
                                                                    <FaRegCommentDots fontSize={20} />
                                                                    <span className="image-text-span fs-6">{data?.project_comments}</span>
                                                                </div>
                                                                <div>
                                                                    <FiEye fontSize={20} />
                                                                    <span className="image-text-span fs-6">{data?.project_views}</span>
                                                                </div>
                                                            </div>
                                                            <div className="all-project-name mt-2">{data?.title}</div>
                                                            <div className="all-project-detail d-lg-block">
                                                                <div className="d-flex gap-2">
                                                                    {data?.user?.full_profile_image !== null ? (
                                                                        <img crossOrigin="anonymous" className="user-profile-image" src={data?.user?.full_profile_image}></img>
                                                                    ) : (
                                                                        <img crossOrigin="anonymous" className="user-profile-image" src={defaultImage}></img>
                                                                    )}
                                                                    <div className="project-author-name">
                                                                        {data?.user?.first_name}&nbsp;{data?.user?.last_name}
                                                                    </div>
                                                                </div>

                                                                <div className="all-project-time">Posted - {timeAgo}.</div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                        <h3>No Impacts Added</h3>
                                        <p className="fs-5">No Impacts available at the moment.</p>
                                    </div>
                                )}
                            </>
                        )}
                        {key == "allProject" && (
                            <>
                                <ShowProjectsDetails
                                    allProjectsData={allProjectsData}
                                    setKey={setKey}
                                    selectedProjectId={selectedProjectId}
                                    projectsData={projectsData}
                                    title={key}
                                    setSelectedProjectId={setSelectedProjectId}
                                />
                            </>
                        )}
                    </>
                )}
                {key == "allProjects" && total > 0 && (
                    <div className="pagination-div my-2">
                        <Pagination count={total} page={page} onChange={handleChange} />
                    </div>
                )}
            </div>
        </>
    );
};

export default AllProjects;
