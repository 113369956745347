import apiClient from "../../utils/apiClient";
import apiService from "../apiService/apiService";

const GET_NOTIFICATION = "/get-notification";
const READ_NOTIFICATION = "/read-notification";
const POST_NOTIFICATION = "/open-notification";
const USER_NOTIFICATIONS = "/notifications";
const READ_ALL_NOTIFICATIONS = "/notifications/read-all";

const getNotification = () => {
    const url = `${GET_NOTIFICATION}`;

    return apiClient.get(url);
};

const readNotification = (ids) => {
    const url = `${READ_NOTIFICATION}`;
    return apiClient.post(url, ids);
};

const postNotification = () => {
    const url = `${POST_NOTIFICATION}`;
    return apiClient.post(url);
};

const getUserNotifications = (cursor) => {
    const params = {
        'cursor': cursor,
    };
    const queryString = apiService.getRequestParams(params);
    const url = `${USER_NOTIFICATIONS}${queryString ? '?' + queryString : ''}`;

    return apiClient.get(url);
};

const readAllNotifications = () => {
    const url = `${READ_ALL_NOTIFICATIONS}`;
    return apiClient.post(url);
};

export const notificationService = {
    getNotification,
    readNotification,
    postNotification,
    getUserNotifications,
    readAllNotifications,
};
