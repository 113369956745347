import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {LessonsService} from "../../../services/lessonsService/lessonsService";

let isPageVisible = true;

const VideoSession = ({ video, id = null, onEnded, autoplay = false }) => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const percentageWatchedRef = useRef(0);
    const totalDurationRef = useRef(0);
    const playerRef = useRef(null);
    const intervalRef = useRef(null);

    const extractYouTubeVideoId = (url) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(youtubeRegex);
        return match ? match[4] : null;
    };

    const extractVimeoVideoData = (url) => {
        const vimeoRegex = /vimeo\.com\/(\d+)(?:\/([\w\d]+))?/;
        const match = url.match(vimeoRegex);

        if (match) {
            return {
                videoId: match[1],
                hashId: match[2] || null
            };
        }

        return null;
    };

    const isYouTubeVideo = video.includes("youtube.com") || video.includes("youtu.be");
    const isVimeoVideo = video.includes("vimeo.com") || video.includes("player.vimeo.com");

    const vimeoVideoData = extractVimeoVideoData(video);

    const videoId = isYouTubeVideo ? extractYouTubeVideoId(video) : isVimeoVideo ? vimeoVideoData.videoId : null;

    const handleTimeUpdate = () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.getCurrentTime();
            const duration = playerRef.current.getDuration();
            const percentage = (currentTime / duration) * 100;
            percentageWatchedRef.current = percentage;
            totalDurationRef.current = duration;
        }
    };

    const handleTimeUpdateVimeo = async () => {
        if (playerRef.current) {
            try {
                const currentTimePromise = playerRef.current.getCurrentTime();
                const durationPromise = playerRef.current.getDuration();

                const [currentTime, duration] = await Promise.all([currentTimePromise, durationPromise]);

                const percentage = (currentTime / duration) * 100;
                percentageWatchedRef.current = percentage;
                totalDurationRef.current = duration;
            } catch (error) {}
        }
    };

    const updateAttendance = async () => {
        if (userType !== 4 && id) {
            const integerValue = Math.floor(totalDurationRef.current);
            const currentTime = await playerRef.current.getCurrentTime();
            LessonsService.updatedLessonProgress(id, integerValue, currentTime)
                .catch((error) => {
                    console.error("API error:", error);
                });
        }
    };

    useEffect(() => {
        // const pauseVideoAndUpdateAttendance = () => {
        //     if (playerRef.current && !playerRef.current.paused) {
        //         // playerRef.current.pause();
        //         console.log('6')
        //         updateAttendance();
        //     }
        // };

        // if (videoId !== (isYouTubeVideo ? extractYouTubeVideoId(video) : extractVimeoVideoId(video))) {
        //     pauseVideoAndUpdateAttendance();
        // }

        const newVideoId = isYouTubeVideo ? extractYouTubeVideoId(video) : isVimeoVideo ? vimeoVideoData.videoId : null;
        if (newVideoId && playerRef.current) {
            if (isYouTubeVideo) {
                // Load YouTube video
                playerRef.current.loadVideoById(newVideoId);
            } else if (isVimeoVideo) {
                // Load Vimeo video
                playerRef.current.loadVideo(newVideoId).then((player) => {
                    player.on("timeupdate", handleTimeUpdate);
                });
            }
        }
        return () => {
            // pauseVideoAndUpdateAttendance();
            clearInterval(intervalRef.current);
        };
    }, [video, videoId, isYouTubeVideo, isVimeoVideo]);

    useEffect(() => {
        const loadYouTubeAPI = () => {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName("script")[0];

            const handleStateChange = (stateEvent) => {
                if (stateEvent.data === window.YT.PlayerState.PLAYING) {
                    intervalRef.current = setInterval(handleTimeUpdate, 1000);
                } else if (stateEvent.data === window.YT.PlayerState.PAUSED) {
                    clearInterval(intervalRef.current);
                    updateAttendance();
                } else if (stateEvent.data === window.YT.PlayerState.ENDED) {
                    if (onEnded) {
                        onEnded();
                    }
                    playerRef.current.pauseVideo();
                    handleTimeUpdate();
                    updateAttendance();
                }
            };

            const onYouTubeIframeAPIReady = () => {
                playerRef.current = new window.YT.Player("video-player", {
                    videoId: videoId,
                    playerVars: {
                        autoplay: autoplay ? 1 : 0,
                        mute: 1,
                    },
                    events: {
                        onReady: (event) => {
                            event.target.addEventListener("onStateChange", handleStateChange);
                            if (autoplay) {
                                event.target.playVideo();
                                setTimeout(() => event.target.unMute(), 2000);
                            }
                        },
                    },
                });
            };

            if (window.YT && window.YT.Player) {
                onYouTubeIframeAPIReady();
            } else {
                window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        };

        const loadVimeoAPI = () => {
            const script = document.createElement("script");
            script.src = "https://player.vimeo.com/api/player.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                const options = {
                    id: videoId,
                    loop: false,
                    h: vimeoVideoData.hashId,
                };

                playerRef.current = new window.Vimeo.Player("video-player", options);

                playerRef.current.on("play", () => {
                    intervalRef.current = setInterval(handleTimeUpdateVimeo, 1000);
                });

                if (autoplay) {
                    playerRef.current.setMuted(true);
                    playerRef.current.play().then(() => {
                        setTimeout(() => playerRef.current.setMuted(false), 2000);
                    }).catch((error) => {
                        console.error("Vimeo autoplay error:", error);
                    });
                }

                playerRef.current.on("pause", () => {
                    clearInterval(intervalRef.current);
                    updateAttendance();
                });

                playerRef.current.on("ended", () => {
                    if (onEnded) {
                        onEnded();
                    }
                    handleTimeUpdateVimeo();
                    updateAttendance();
                });
            };
        };

        if (isYouTubeVideo) {
            loadYouTubeAPI();
        } else if (isVimeoVideo) {
            loadVimeoAPI();
        }

        return () => {
            if (window.YT && window.YT.Player && isYouTubeVideo) {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }
                clearInterval(intervalRef.current);
            } else if (window.Vimeo && isVimeoVideo) {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }
                clearInterval(intervalRef.current);
            }
        };
    }, [video, videoId, isYouTubeVideo, isVimeoVideo]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            isPageVisible = !document.hidden;

            if (!isPageVisible && playerRef.current && !playerRef.current.paused) {
                playerRef.current.pause();
                updateAttendance();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [video, videoId, isYouTubeVideo, isVimeoVideo]);

    return (
        <>
            <div>
                <div id="video-player" className="youtube-video vimeo-video"></div>
            </div>
        </>
    );
};

export default VideoSession;
