import {NotificationContent} from "./components/NotificationContent";
import './Notification.css';
import avatarUser from "../../assets/profile-settings/avatarImage.jpg";
import logo from "../../assets/paxton-patterson-logo.png";

export const Notification = ({ notification }) => {

    const avatar = notification.sender?.avatar ? notification.sender.avatar : avatarUser;
    const notificationImage = notification.sender ? avatar : logo;

    return (
        <div className={`notification ${notification.is_read ? "notification__read" : ""}`}>
            <div style={{marginRight: 8, display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                <div className="notification__avatar">
                    <img src={notificationImage} alt="User avatar"/>
                </div>
            </div>
            <NotificationContent
                notification={notification}
            />
        </div>
    );
};