import { useState, useRef, useEffect } from "react";

const SpeakerBio = ({ bio }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isClamped, setIsClamped] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            const lineHeight = parseInt(getComputedStyle(textRef.current).lineHeight);
            const maxHeight = lineHeight * 3;
            setIsClamped(textRef.current.scrollHeight > maxHeight);
        }
    }, [bio]);

    return (
        <div className="speaker-bio-container">
            <div
                ref={textRef}
                className={`speaker-bio ${isExpanded ? "expanded" : ""}`}
            >
                {bio}
            </div>
            {isClamped && (
                <button className="show-more-btn" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? "Show less" : "Show more"}
                </button>
            )}
        </div>
    );
};

export default SpeakerBio;