import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import TextEditorContent from "../../../components/TextEditorContent";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import EditCommunityDiscussionModal from "../modals/EditCommunityDiscussionModal";
import {FaRegEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";

const GeneralDiscussionDetails = () => {
    const [generalDiscussionDetails, setGeneralDiscussionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { categoryId, id } = useParams();
    const [showEditModal, setShowEditModal] = useState(false);
    const userData = useSelector((state) => state?.auth?.userData);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getGeneralDiscussionDetails();
    }, []);

    const getGeneralDiscussionDetails = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getGeneralDiscussionDetails(categoryId, id);
            setGeneralDiscussionDetails(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteConfirmation = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this discussion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete();
            }
        });
    };

    const handleDelete = async () => {
        try {
            await communityService.deleteGeneralCommunityDiscussion(categoryId, id);
            navigate(`/community/${categoryId}/discussions`);
            toast.success('Discussion deleted successfully!');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to delete comment');
        }
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="container m-4">
                            <div className="row">
                                <CustomBreadcrumbs
                                    currentPageTitle={generalDiscussionDetails?.title}
                                    pages={[
                                        {
                                            title: 'Community',
                                            to: `/community`
                                        },
                                        {
                                            title: generalDiscussionDetails?.category?.title,
                                            to: `/community/${generalDiscussionDetails?.category?.id}/discussions`
                                        },
                                    ]}
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <h1>{generalDiscussionDetails?.title}</h1>
                                    {generalDiscussionDetails?.user?.id === userData?.id &&
                                        <div className="d-flex justify-content-end my-2">
                                            <div className="d-flex align-items-center gap-1">
                                                <button className="border-0 p-0"
                                                        style={{background: "unset", color: "#198754"}}
                                                        onClick={() => setShowEditModal(true)}
                                                >
                                                    <FaRegEdit fontSize={20}/>
                                                </button>
                                                <button className="border-0 p-0"
                                                        style={{background: "unset", color: "rgba(139, 0, 50, 1)"}}
                                                        onClick={handleDeleteConfirmation}
                                                >
                                                    <MdDelete fontSize={24}/>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    <TextEditorContent
                                        text={generalDiscussionDetails?.description}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="master-class__comments">
                                        <DiscussionsCommentList
                                            path={`community/general-discussions/${id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showEditModal &&
                            <EditCommunityDiscussionModal
                                onClose={() => setShowEditModal(false)}
                                selectedDiscussionId={id}
                                discussionData={generalDiscussionDetails}
                                onUpdateDiscussion={(discussion) => setGeneralDiscussionDetails(discussion)}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default GeneralDiscussionDetails;