import React, { useState } from "react";
import InnerLayout from "../../layout/InnerLayout";
import User1 from "../../assets/user1.png";
import User2 from "../../assets/user2.png";
import Send from "../../assets/send.png";
import PaperClip from "../../assets/paperclip.png";
import Smile from "../../assets/smile.png";
import "./chat.css";
import Minus from "../../assets/minus.png";
import FAQ from "./components/faq";

const Chat = () => {
    const [key, setKey] = useState("faq");
    const [selectedCategory, setSelectedCategory] = useState(1);

    return (
        <>
            <h3 className="d-lg-block d-none title">FAQ</h3>
            <div className="outer-card-layout mt-4">
                <ul className="nav nav-underline">
                    {/* <li className="nav-item">
                        <a className={key == "supportChat" ? "nav-link active" : "nav-link"} onClick={() => setKey("supportChat")} aria-current="page">
                            <span className="color-white">Support Chat</span>
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <a className={selectedCategory == 1 ? "nav-link active" : "nav-link"} onClick={() => setSelectedCategory(1)}>
                            <span className="color-white">FAQ</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={selectedCategory == 2 ? "nav-link active" : "nav-link"} onClick={() => setSelectedCategory(2)}>
                            <span className="color-white">Master Course</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={selectedCategory == 3 ? "nav-link active" : "nav-link"} onClick={() => setSelectedCategory(3)}>
                            <span className="color-white">Catapult</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={selectedCategory == 4 ? "nav-link active" : "nav-link"} onClick={() => setSelectedCategory(4)}>
                            <span className="color-white">Technical</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={selectedCategory == 5 ? "nav-link active" : "nav-link"} onClick={() => setSelectedCategory(5)}>
                            <span className="color-white">Community</span>
                        </a>
                    </li>
                </ul>
                {key === "supportChat" && (
                    <>
                        <div className="mt-4">
                            <div className="d-flex l-container mt-4">
                                <div>
                                    <img crossOrigin="anonymous" src={User1}></img>
                                </div>
                                <div>
                                    <div className="user-name">Dr. Fleming</div>
                                    <div className="white-message mt-2">Need Support about Recording Video, Can you please help?</div>
                                    <div className="mt-2 time">1:55pm</div>
                                </div>
                            </div>
                            <div className="d-flex r-container mt-4">
                                <div>
                                    <div className="user-name">Technical Assistant (Smith)</div>
                                    <div className="blue-message mt-2">Need Support about Recording Video, Can you please help?</div>
                                    <div className="mt-2 time">1:55pm</div>
                                </div>
                                <div>
                                    <img crossOrigin="anonymous" src={User2}></img>
                                </div>
                            </div>
                            <div className="d-flex l-container mt-4">
                                <div>
                                    <img crossOrigin="anonymous" src={User1}></img>
                                </div>
                                <div>
                                    <div className="user-name">Dr. Fleming</div>
                                    <div className="white-message mt-2">Need Support about Recording Video, Can you please help?</div>
                                    <div className="mt-2 time">1:55pm</div>
                                </div>
                            </div>
                            <div className="d-flex r-container mt-4">
                                <div>
                                    <div className="user-name">Technical Assistant (Smith)</div>
                                    <div className="blue-message mt-2">Need Support about Recording Video, Can you please help?</div>
                                    <div className="mt-2 time">1:55pm</div>
                                </div>
                                <div>
                                    <img crossOrigin="anonymous" src={User2}></img>
                                </div>
                            </div>
                            <div className="mt-4 text-row">
                                <textarea className="text-area" placeholder="Type something here..." rows={5}></textarea>
                                <div className="divder"></div>
                                <div className="send">
                                    <div className="btn-send">
                                        <img crossOrigin="anonymous" src={Send}></img>
                                    </div>
                                    <div className="d-flex icn">
                                        <div>
                                            <img crossOrigin="anonymous" src={PaperClip}></img>
                                        </div>
                                        <div>
                                            <img crossOrigin="anonymous" src={Smile}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {key === "faq" && (
                    <>
                        <FAQ category={selectedCategory} />
                    </>
                )}
            </div>
        </>
    );
};
export default Chat;
