import React, {useEffect, useState} from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/common/Loader";
import "../programs.css";
import {programsService} from "../../../services/programsService/programsService";
import ProgramMasterclassList from "./ProgramMasterclassList";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import ProgramCompleteInfoModal from "./modals/ProgramCompleteInfoModal";
import {IoCheckmark} from "react-icons/io5";
import useDownload from "../../../hooks/useDownload";
import usePusher from "../../../hooks/usePusher";
import {useSelector} from "react-redux";
import {PageErrorMessage} from "../../../components/PageErrorMessage";
import TextEditorContent from "../../../components/TextEditorContent";

const ProgramDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [programDetails, setProgramDetails] = useState(null);
    const { id } = useParams();
    const downloadFile = useDownload();
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const authUser = useSelector((state) => state?.auth?.auth);
    const pusher = usePusher();
    const [error, setError] = useState(null);

    const programDetailsData = async () => {
        setIsLoading(true);
        try {
            await programsService.getSingleProgramData(id).then((response) => {
                setProgramDetails(response?.data);
            });
            setIsLoading(false);
        } catch (error) {
            setError({
                statusCode: error.response.status,
                message: error.response.data.message,
            });
            setIsLoading(false);
        }
    };

    // useEffect of call all api function
    useEffect(() => {
        programDetailsData();
    }, [id]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleBack = () => {
        navigate(-1);
        scrollToTop();
    };

    const firstUncompletedMasterClass = programDetails?.master_classes.find(
        masterClass => masterClass.is_completed === false && masterClass.first_lesson !== null
    ) || null;

    const handleBeginProgram = () => {
        if (firstUncompletedMasterClass !== null) {
            navigate('/lessons/' + firstUncompletedMasterClass.first_lesson);
        }
    };

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
    };

    const handleDownloadCertificate = async () => {
        if (programDetails.certificate) {
            const filename = (programDetails?.name || "certificate").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            await downloadFile(programDetails.certificate, filename);
        }
    };

    useEffect(() => {
        if (pusher) {
            const channel = pusher.subscribe('survey-completed.' + authUser.id);
            channel.bind('survey-completed', function(data) {
                setProgramDetails((prevState) => ({
                    ...prevState,
                    is_completed: true,
                }));
            });
        }
    }, [pusher]);

    if (error) {
        return <PageErrorMessage
            error={error}
            entityTitle="Program"
        />;
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div className="container-fluid">
                        <div className="row mb-5 py-5 px-4 program__content">
                            <div className="col-md-6 text-center">
                                <div className="program__image">
                                    <img
                                        src={programDetails?.cover_image}
                                        alt={programDetails?.title}
                                        className="img-fluid"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 mb-4 mb-md-0">
                                <h1>{programDetails?.name}</h1>
                                <TextEditorContent
                                    text={programDetails?.description}
                                />
                                <div className="d-flex mt-3">
                                    <button
                                        className="btn btn-primary-info me-2"
                                        disabled={firstUncompletedMasterClass === null}
                                        onClick={handleBeginProgram}
                                    >
                                        Begin
                                    </button>
                                    {(!programDetails?.is_completed && programDetails?.progress === 100) &&
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => setShowInfoModal(true)}
                                        >
                                            Complete to View Your Certificate
                                        </button>
                                    }
                                    {(!programDetails?.is_completed && programDetails?.progress !== 100) &&
                                        <button
                                            className="btn btn-primary"
                                            disabled={true}
                                            onClick={() => setShowInfoModal(true)}
                                        >
                                            Complete to View Your Certificate
                                        </button>
                                    }
                                    {programDetails?.is_completed &&
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleDownloadCertificate}
                                        >
                                            View Your Certificate
                                        </button>
                                    }
                                </div>
                                <div className="mt-3">
                                    <div className="fs-6 lh-base fw-bolder">
                                        Program Progress
                                    </div>
                                    <ProgressBar completed={programDetails?.progress} bgColor="#1c64f2"
                                                 baseBgColor="#374151"
                                                 className={`w-100 progressbar-count ${programDetails?.progress > 5 ? '' : 'progressbar-count__hide-value'}`}/>
                                </div>
                                <div className="d-flex align-center mt-3">
                                    <div className="fs-6 lh-base fw-bolder">
                                        Credit Hours: {programDetails?.credit_hours}
                                    </div>
                                </div>
                                <div className="d-flex mt-3 gap-2">
                                    {programDetails?.is_completed &&
                                        <button className="btn btn-secondary d-flex align-center" disabled={true}>
                                            Completed <IoCheckmark/>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <ProgramMasterclassList
                            masterClasses={programDetails?.master_classes}
                        />
                    </div>
                </>
            )}
            {showInfoModal &&
                <ProgramCompleteInfoModal
                    handleClose={handleCloseInfoModal}
                    program={programDetails}
                    onUpdateProgram={(updatedProgram) => setProgramDetails(updatedProgram)}
                />
            }
        </>
    );
};

export default ProgramDetails;