import React from "react";
import DashboardMasterclassCard from "./DashboardMasterclassCard";

const DashboardMasterclassesList = ({ masterClasses }) => {

    return (
        <>
            <h3>Master Courses</h3>
            <div className="row">
                {masterClasses?.map((masterClass) => {
                    return (
                        <React.Fragment key={masterClass.id}>
                            <DashboardMasterclassCard
                                masterClass={masterClass}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default DashboardMasterclassesList;