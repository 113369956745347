import React, {useEffect, useState} from "react";
import Loader from "../../components/common/Loader";
import {Pagination} from "../../components/Pagination/Pagination";
import {userService} from "../../services/usersService/userService";
import {useNavigate} from "react-router-dom";
import useRenderTextEditorContent from "../../hooks/useTextEditorContent";
import avatarUser from "../../assets/profile-settings/avatarImage.jpg";
import {FiSearch} from "react-icons/fi";
import useDebounce from "../../hooks/useDebounce";
import notFound from "../../assets/not-found/not_found.png";

const UsersOnline = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [requestPage, setRequestPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();
    const debouncedSearchTerm = useDebounce(searchTerm, 750);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getOnlineUsers();
    }, [requestPage, debouncedSearchTerm]);

    useEffect(() => {
        setRequestPage(1);
    }, [debouncedSearchTerm]);

    const getOnlineUsers = async () => {
        setIsLoading(true);
        try {
            const response = await userService.getOnlineUsers(12, requestPage, searchTerm);
            setUsers(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setRequestPage(page);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <h3 className="d-lg-block">Online Members</h3>
                    <div className="search-container">
                        <input onChange={handleSearch} placeholder="Search" className="search-input"/>
                        <FiSearch className="search-icon"/>
                    </div>
                </div>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        {users?.length > 0 ? (
                            <>
                                <div className="row">
                                    {users?.map((user) => {
                                        return (
                                            <React.Fragment key={user.id}>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-12 text-center">
                                                    <div
                                                        className={`card text-white m-3 p-4 program-masterclass-card rounded-3`}
                                                        onClick={() => navigate(`/community/users/${user.id}`)}
                                                    >
                                                        <div className="profile-image mb-1">
                                                            <figure style={{position: "relative", overflow: "hidden"}}>
                                                                <img
                                                                    crossOrigin="anonymous"
                                                                    className="img-fluid object-fit-cover"
                                                                    src={user?.avatar || avatarUser}
                                                                    alt="Profile Image"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <h5 className="card-title mb-3">{user?.name}</h5>
                                                        <div className="program-masterclass-card-description mb-3">
                                                            {
                                                                user?.bio && renderAnswer(user?.bio)?.map((element, index) =>
                                                                    <React.Fragment key={index}>{element}</React.Fragment>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <div className="mt-2">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </>
                        ) : (
                            <div
                                className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                style={{height: "400px"}}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={200}/>
                                <h4>No Results</h4>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default UsersOnline;