export const DefaultNotification = ({ notification }) => {
    const message = notification.message;

    return (
        <div className="notification__content">
            <div className="notification__content__heading">
                <div className="notification__content__heading__time">
                    {notification.created_at}
                </div>
            </div>
            <div className="notification__content__data">
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{wordBreak: "break-all"}}>
                        {message}
                    </div>
                </div>
            </div>
        </div>
    );
};