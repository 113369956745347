import {NotificationMessage} from "./NotificationMessage";

export const LiveEventScheduledNotification = ({ notification }) => {
    const event = notification.entities.find((dataEntity) => dataEntity.type === 'special-event').data;
    const eventId = event.id;
    const message = notification.message;
    const linkTo = event.alternative_meeting_url ? null : '/live-events/' + eventId;

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <NotificationMessage
                message={message}
                linkTo={linkTo}
                url={event.alternative_meeting_url}
            />
        </div>
    );
};