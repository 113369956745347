import {Link} from "react-router-dom";

export const NotificationMessage = ({ message, linkTo, url }) => {

    const regex = /\*(.*?)\*/g;
    const parts = message.split(regex);

    return (
        <div style={{wordBreak: "break-all"}}>
            {parts.map((part, index) => {
                if (index % 2 === 1) {

                    if (linkTo && !url) {
                        return (
                            <Link
                                to={linkTo}
                                key={index}
                                className="notification__message-link"
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                {part}
                            </Link>
                        );
                    }

                    if (url && !linkTo) {
                        return (
                            <a className="notification__message-link" target="_blank" href={url}>
                                {part}
                            </a>
                        );
                    }

                    return <></>;
                } else {
                    return (
                        <span key={index} className="notification__message-text">
                            {part}
                        </span>
                    );
                }
            })}
        </div>
    );
};