import React, {useEffect, useState} from "react";
import {MasterClassService} from "../../../services/masterClassService/masterClassService";
import Loader from "../../../components/common/Loader";
import MasterClassesList from "./MasterClassesList";
import notFound from "../../../assets/not-found/not_found.png";
import ProgramMasterclassCard from "../../programs/components/ProgramMasterclassCard";
import useDebounce from "../../../hooks/useDebounce";
import {FiSearch} from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentLoader from "../../../components/common/CommentLoader";

const InstitutionUserMasterclasses = () => {
    const [masterClasses, setMasterClasses] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        setPage(1);
        getAllMasterClasses(1, true);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!hasMore && masterClasses.length > 0) {
            setIsRecommendationsLoading(true);
            getRecommendations();
        }
    }, [masterClasses]);

    const getAllMasterClasses = async (currentPage = page, isNewSearch = false) => {
        try {
            const response = await MasterClassService.getMasterClasses(currentPage, searchTerm);
            const newMasterClasses = response.data.data;

            if (isNewSearch) {
                setMasterClasses(newMasterClasses);
            } else {
                setMasterClasses(prevMasterClasses => [...prevMasterClasses, ...newMasterClasses]);
            }

            if (newMasterClasses.length === 0) {
                setHasMore(false);
            } else {
                setPage(currentPage + 1);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getRecommendations = async () => {
        try {
            const response = await MasterClassService.getRecommendations();
            setRecommendations(response.data);
            setIsRecommendationsLoading(false);
        } catch (error) {
            setIsRecommendationsLoading(false);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h3 className="d-lg-block">Master Courses</h3>
                    <div className="search-container">
                        <input onChange={handleSearch} value={searchTerm} placeholder="Search"
                               className="search-input" autoFocus={true}/>
                        <FiSearch className="search-icon"/>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="masterclasses-scroll-container">
                        {masterClasses.length > 0 && (
                            <InfiniteScroll
                                next={() => getAllMasterClasses(page)}
                                hasMore={hasMore}
                                loader={isLoading ? <CommentLoader/> : <></>}
                                dataLength={masterClasses.length}
                            >
                                <div className="masterclasses-scroll-content">
                                    <MasterClassesList masterClasses={masterClasses} />
                                </div>
                            </InfiniteScroll>
                        )}
                        {masterClasses?.length === 0 && (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h3>No Master Courses Added</h3>
                                <p className="fs-5">No Master Courses available at the moment.</p>
                            </div>
                        )}
                    </div>
                )}
                {!hasMore &&
                    <>
                        <h3 className="d-lg-block mt-3">Recommendations</h3>
                        {isRecommendationsLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                {recommendations.length > 0 && (
                                    <>
                                        <div className="row">
                                            {recommendations?.map((masterClass) => {
                                                return (
                                                    <React.Fragment key={masterClass.id}>
                                                        <ProgramMasterclassCard
                                                            masterClass={masterClass}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                                {recommendations?.length === 0 && (
                                    <div
                                        className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                        style={{height: "500px"}}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
                                        <h3>No Recommendations</h3>
                                        <p className="fs-5">You don't have any recommendations at the moment.</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                }
            </div>
        </>
    );
};

export default InstitutionUserMasterclasses;