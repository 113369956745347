import React from "react";
import {useSelector} from "react-redux";
import DiscussionByCategory from "./DiscussionByCategory";

const DiscussionsByCategories = () => {
    const discussionCategories = useSelector((state) => state?.auth?.discussionCategories);

    return (
        <>
            {discussionCategories?.map((category) => {
                return (
                    <React.Fragment key={category.id}>
                        <DiscussionByCategory
                            discussionCategory={category}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default DiscussionsByCategories;