import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useDownload from "../../../hooks/useDownload";
import {MasterClassService} from "../../../services/masterClassService/masterClassService";
import Loader from "../../../components/common/Loader";
import avatarUser from "../../../assets/avatarUser.png";
import MasterClassLessonsList from "./MasterClassLessonsList";
import notFound from "../../../assets/not-found/not_found.png";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import {PageErrorMessage} from "../../../components/PageErrorMessage";
import UserDetails from "../../../components/UserDetails";
import TextEditorContent from "../../../components/TextEditorContent";

const SpeakerMasterClassDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [masterClassDetails, setMasterClassDetails] = useState(null);
    const { id } = useParams();
    const downloadFile = useDownload();
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();
    const [error, setError] = useState(null);

    const masterClassDetailsData = async () => {
        setIsLoading(true);
        try {
            await MasterClassService.getSpeakerMasterClassData(id).then((response) => {
                setMasterClassDetails(response?.data);
            });
            setIsLoading(false);
        } catch (error) {
            setError({
                statusCode: error.response.status,
                message: error.response.data.message,
            });
            setIsLoading(false);
        }
    };

    // useEffect of call all api function
    useEffect(() => {
        masterClassDetailsData();
    }, [id]);

    const handleDownloadPlaybook = async () => {
        if (masterClassDetails.playbook_pdf) {
            const filename = (masterClassDetails?.name || "playbook_file").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            await downloadFile(masterClassDetails.playbook_pdf, filename);
        }
    };

    if (error) {
        return <PageErrorMessage
            error={error}
            entityTitle="Masterclass"
        />;
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div className="container m-4">
                        <div className="row mb-5">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h1>{masterClassDetails?.name}</h1>
                                <TextEditorContent
                                    text={masterClassDetails?.description}
                                />
                                <div className="d-flex mt-3">
                                    {masterClassDetails?.playbook_pdf &&
                                        <button className="btn btn-primary me-2"
                                                disabled={!masterClassDetails?.playbook_pdf}
                                                onClick={handleDownloadPlaybook}>Seasonal Installer Interest Flyer</button>
                                    }
                                    <button
                                        className="btn btn-primary-info"
                                        onClick={() => navigate('/lessons/' + masterClassDetails?.first_lesson)}
                                        disabled={masterClassDetails?.first_lesson === null}
                                    >
                                        Begin Master Course
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-6 text-center">
                                <div className="master-class__image">
                                    <img
                                        src={masterClassDetails?.cover_image}
                                        alt={masterClassDetails?.name}
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="master-class__speaker-info p-3 mt-3">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div onClick={() => navigate(`/community/users/${masterClassDetails?.speaker.id}`)}>
                                            <UserDetails
                                                user={masterClassDetails?.speaker}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            {
                                                masterClassDetails?.speaker?.bio && renderAnswer(masterClassDetails?.speaker?.bio)?.map((element, index) =>
                                                    <React.Fragment key={index}>{element}</React.Fragment>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {masterClassDetails?.lessons.length > 0 &&
                                    <MasterClassLessonsList
                                        lessons={masterClassDetails?.lessons}
                                        withProgress={false}
                                    />
                                }
                                {masterClassDetails?.lessons.length === 0 &&
                                    <div
                                        className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                        style={{height: "500px"}}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
                                        <h3>No Lessons Added</h3>
                                        <p className="fs-5">No lessons available at the moment.</p>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="master-class__comments">
                                    <DiscussionsCommentList
                                        path={`master-classes/${masterClassDetails?.id}`}
                                        relatedTitlesToDisplay={['lessons']}
                                        highlightingCompareId={masterClassDetails?.speaker.id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SpeakerMasterClassDetails;