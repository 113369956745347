import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import ModalClose from "../../../../assets/modal_close.png";
import {Rating} from "@mui/material";
import Swal from "sweetalert2";
import {MasterClassService} from "../../../../services/masterClassService/masterClassService";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";

const MasterClassCompleteInfoModal = ({ handleClose, masterClass, onUpdateMasterClass }) => {

    const navigate = useNavigate();
    const [showRecommendations, setShowRecommendations] = useState(false);
    const [rating, setRating] = useState(masterClass?.rating);

    const handleRateMasterClass = async (ratingValue) => {
        try {
            setRating(ratingValue);
            const updatedMasterClass = await MasterClassService.rateMasterClass(masterClass.id, ratingValue);
            onUpdateMasterClass(updatedMasterClass.data);
            Swal.fire("Thanks!", "Masterclass has been rated.", "success");
            setShowRecommendations(true);
        } catch (err) {
            Swal.fire("Error!", "Failed to rate the masterclass.", "error");
        }
    };

    const closeModal = () => {
        setShowRecommendations(false);
        handleClose();
    };

    const handleCompleteMasterClass = async () => {
        try {
            const updatedMasterClass = await MasterClassService.completeMasterclass(masterClass.id);
            onUpdateMasterClass(updatedMasterClass.data);
            closeModal();
            if (masterClass.program) {
                navigate(`/programs/${masterClass.program.id}`);
            }
            if (!masterClass.program) {
                navigate(`/master-classes`);
            }
            toast.success(`${masterClass.name} has been completed.`);
        } catch (err) {
            Swal.fire("Error!", "Failed to complete masterclass.", "error");
        }
    };

    return (
        <>
            <Modal onHide={handleClose} centered className="resources-file-uploaded-popup" show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="modal-header m-0">
                    <div className="question">You've completed {masterClass?.name}</div>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose} alt="Close" />
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{maxHeight: "450px", overflowY: "scroll", display: "block"}}
                >
                    <div className="row mt-3 px-3">
                        {masterClass?.badges.length !== 0 &&
                            <div className="box col-sm-12 col-lg-12 col-md-12 flex-column gap-3 align-items-center">
                                <div className="question">
                                    Achievement Unlocked!
                                </div>
                                <div className="d-flex justify-content-center flex-wrap">
                                    {masterClass?.badges.map((achievement, index) => {
                                        return <div className="text-center user-profile-achievements__item" key={index}>
                                            <img className="user-profile-achievements__item__logo"
                                                 src={achievement.logo}/>
                                            <span>{achievement.name}</span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                        {masterClass?.survey_url &&
                            <div className="d-flex mt-3">
                                <a target="_blank" href={masterClass?.survey_url}>
                                    <button className="btn btn-primary-info">Complete Survey</button>
                                </a>
                            </div>
                        }
                        {masterClass?.test_url &&
                            <div className="d-flex mt-3">
                                <a target="_blank" href={masterClass?.test_url}>
                                    <button className="btn btn-primary">Complete quiz for credit</button>
                                </a>
                            </div>
                        }
                        <div className="mt-3 rating-title">
                            Rate this master course:
                        </div>
                        <Rating value={rating} onChange={(event, value) => handleRateMasterClass(value)}/>
                        {masterClass.recommendations.length > 0 && showRecommendations &&
                            <>
                                <div className="mt-3 rating-title">
                                    If you like this masterclass, then you might like this:
                                </div>
                                <div className="row">
                                    {masterClass.recommendations?.map((recommendation) => {
                                        return (
                                            <React.Fragment key={recommendation.id}>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-12">
                                                    <div
                                                        className="card text-white m-3 p-4 program-masterclass-card rounded-3"
                                                        onClick={() => {
                                                            navigate("/master-classes/" + recommendation.id);
                                                            closeModal();
                                                        }}>
                                                        <img src={recommendation?.cover_image}
                                                             className="card-img-top program-masterclass-card-thumbnail img-fluid rounded-3 mb-3"
                                                             alt={recommendation?.name}/>
                                                        <h6 className="card-title">{recommendation?.name}</h6>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary add-btn"
                        onClick={handleCompleteMasterClass}
                    >
                        Complete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MasterClassCompleteInfoModal;