import apiClient from "../../utils/apiClient";

const GET_PROGRAMS = "/programs";
const GET_PROGRAM_BY_ID = "programs";

const getPrograms = () => {
    return apiClient.get(GET_PROGRAMS);
}

const getSingleProgramData = (id) => {
    const url = `${GET_PROGRAM_BY_ID}/${id}`;
    return apiClient.get(url);
};

const rateProgram = (id, rating) => {
    const data = {
        rating: rating
    };
    const url = `programs/${id}/rate`;

    return apiClient.post(url, data);
};

const completeProgram = (id) => {
    const url = `programs/${id}/complete`;
    return apiClient.post(url);
};

export const programsService = {
    getPrograms,
    getSingleProgramData,
    rateProgram,
    completeProgram
}