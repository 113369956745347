import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import lessonsAutoplayReducer from './lessonsAutoplaySlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import chatReducer from './messengerSlice';

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["auth", "keynoteDate", "email", "userData", "progressBar", "accessToken", "discussionCategories"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
    reducer: {
        auth: persistedReducer,
        lessonsAutoplay: lessonsAutoplayReducer,
        chat: chatReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
export const persistor = persistStore(store);
