import React, { useState, useEffect } from "react";
import "./community.css";
import { communityService } from "../../services/communityService/communityService";
import Loader from "../../components/common/Loader";
import DiscussionsList from "./components/DiscussionsList";
import {userService} from "../../services/usersService/userService";
import {Avatar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CommunitySearch from "./components/CommunitySearch";
import DiscussionsByCategories from "./components/DiscussionsByCategories";

const Community = () => {
    const [masterClassDiscussions, setMasterClassDiscussions] = useState([]);
    const [memberDiscussions, setMemberDiscussions] = useState([]);
    const [communityQuestions, setCommunityQuestions] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [totalOnline, setTotalOnline] = useState([]);
    const [isMasterClassDiscussionsLoading, setIsMasterClassDiscussionsLoading] = useState(false);
    const [isCommunityQuestionsLoading, setIsCommunityQuestionsLoading] = useState(false);
    const [isMemberDiscussionsLoading, setIsMemberDiscussionsLoading] = useState(false);
    const [isOnlineUsersLoading, setIsOnlineUsersLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getMasterClassDiscussions();
        getCommunityQuestions();
        getMemberDiscussions();
        getOnlineUsers();
    }, []);

    const getMasterClassDiscussions = async () => {
        setIsMasterClassDiscussionsLoading(true);
        try {
            const response = await communityService.getMasterClassDiscussions(3);
            setMasterClassDiscussions(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMasterClassDiscussionsLoading(false);
        }
    };

    const getCommunityQuestions = async () => {
        setIsCommunityQuestionsLoading(true);
        try {
            const response = await communityService.getCommunityQuestions(3);
            setCommunityQuestions(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsCommunityQuestionsLoading(false);
        }
    };

    const getMemberDiscussions = async () => {
        setIsMemberDiscussionsLoading(true);
        try {
            const response = await communityService.getMemberDiscussions(3);
            setMemberDiscussions(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMemberDiscussionsLoading(false);
        }
    };

    const getOnlineUsers = async () => {
        setIsOnlineUsersLoading(true);
        try {
            const response = await userService.getOnlineUsers(5);
            setOnlineUsers(response.data.data);
            setTotalOnline(response.data.meta.total);
        } catch (error) {
            console.log(error);
        } finally {
            setIsOnlineUsersLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid">
                {isOnlineUsersLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex text-center flex-column mb-3 community__main-title py-5 px-4 sm:py-8 lg:px-5"
                                 style={{paddingTop: "1.5rem"}}>
                                <h1>Paxton/Patterson Community</h1>
                                <h3 className="text-second">Welcome to the Paxton/Patterson community!</h3>
                                <span className="fs-5 text-second fs-lg-4">
                                    Join in on questions and conversations with other Paxton/Patterson members, or find community members by skills, name, role, and more!
                                </span>
                                <CommunitySearch />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <h4 className="d-lg-block">{`${totalOnline} Members Online`}</h4>
                        </div>
                        <div className="d-flex flex-wrap mb-4">
                            {onlineUsers.map((user) => {
                                return (
                                    <div className="cursor-pointer mr-5"
                                         onClick={() => navigate(`/community/users/${user.id}`)}>
                                        <Avatar key={user.id} src={user.avatar}/>
                                    </div>
                                )
                            })}
                            {totalOnline > 5 &&
                                <button className="btn btn-primary"
                                        onClick={() => navigate('/community/users-online')}>{`${totalOnline - 5} more`}</button>
                            }
                        </div>
                    </>
                )}
                {isMasterClassDiscussionsLoading ? (
                    <Loader/>
                ) : (
                    <DiscussionsList
                        title="Speaker Discussions"
                        discussions={masterClassDiscussions}
                        navigateTo="/master-class/discussions/"
                        sideComponent={
                            <button className="btn btn-primary" onClick={() => navigate("/master-class/discussions/")}>View All</button>
                        }
                    />
                )}
                {isCommunityQuestionsLoading ? (
                    <Loader/>
                ) : (
                    <DiscussionsList
                        title="Questions for the community"
                        discussions={communityQuestions}
                        navigateTo="/community/questions-for-the-community/"
                        viewAllPath="/community/questions-for-the-community/"
                        sideComponent={
                            <button className="btn btn-primary" onClick={() => navigate("/community/questions-for-the-community/")}>View All</button>
                        }
                    />
                )}
                <DiscussionsByCategories />
                {isMemberDiscussionsLoading ? (
                    <Loader/>
                ) : (
                    <DiscussionsList
                        title="Member Discussions"
                        discussions={memberDiscussions}
                        navigateTo="/community/member-discussions/"
                        viewAllPath="/community/member-discussions/"
                        sideComponent={
                            <button className="btn btn-primary" onClick={() => navigate("/community/member-discussions/")}>View All</button>
                        }
                    />
                )}
            </div>
        </>
    );
};

export default Community;
