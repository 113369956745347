import React, {useState} from "react";
import "./AchievementsListCard.css";
import {Button, Modal} from "react-bootstrap";
import ModalClose from "../../assets/modal_close.png";
import TextEditorContent from "../TextEditorContent";

const AchievementsListCard = ({ title = 'Achievements', achievements, unopenedBadges = [] }) => {

    const [selectedAchievement, setSelectedAchievement] = useState(null);

    const closeSelectedAchievementModal = () => {
        setSelectedAchievement(null);
    };

    const handleSelectAchievement = (achievement) => {
        setSelectedAchievement(achievement);
    };

    return (
        <>
            <div className="user-profile-achievements p-3">
                <h5>{title}</h5>
                <div className="d-flex justify-content-start flex-wrap my-4">
                    {achievements.map((achievement) => {
                        return <div className="text-center user-profile-achievements__item cursor-pointer" key={achievement.id} onClick={() => handleSelectAchievement(achievement)}>
                            <img className="user-profile-achievements__item__logo" src={achievement.logo}/>
                            <div>{achievement.name}</div>
                        </div>
                    })}
                    {unopenedBadges.map((achievement) => {
                        return <div className="text-center user-profile-achievements__item cursor-pointer badge-locked" key={achievement.id} onClick={() => handleSelectAchievement(achievement)}>
                            <img className="user-profile-achievements__item__logo" src={achievement.logo}/>
                            <div>{achievement.name}</div>
                        </div>
                    })}
                </div>
            </div>
            {selectedAchievement &&
                <Modal onHide={closeSelectedAchievementModal} centered show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header className="modal-header m-0">
                        <div className="question">{selectedAchievement.name}</div>
                        <div className="modal-close" onClick={() => closeSelectedAchievementModal()}>
                            <img src={ModalClose} alt="Close" />
                        </div>
                    </Modal.Header>
                    <Modal.Body
                        style={{maxHeight: "450px", overflowY: "scroll"}}
                    >
                        <div className="row mt-3 px-3">
                            <TextEditorContent
                                text={selectedAchievement.description}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default AchievementsListCard;